<template>
  <div>
    <h2>Redirect to ticketing system</h2>
  </div>
</template>
<script>

export default {

  created() {
    window.location.href = "https://gestion.konek.ai/fr_CA/my/tickets";
  },

};
</script>
